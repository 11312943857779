import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  marginLeft: theme.spacing(4),
}))

const MessageBubble = styled(Box)(({ theme }) => ({
  position: 'relative',
  minWidth: '20%',
  maxWidth: '50%',
  backgroundColor: '#F5F5F7',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1.5, 2),
}))

const ResponseLoading = () => (
  <>
    <LoadingContainer>
      <Typography variant="body2" fontWeight="bold">
        Turn Loading...
      </Typography>
    </LoadingContainer>
    <LoadingContainer>
      <Avatar
        sx={(theme) => ({
          height: 24,
          width: 24,
          backgroundColor: theme.palette.grey[300],
        })}
      />
      <MessageBubble>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CircularProgress size={16} />
          <Typography variant="body2" color="text.secondary">
            Generating Responses...
          </Typography>
        </Box>
      </MessageBubble>
    </LoadingContainer>
  </>
)

export { ResponseLoading }


