import classNames from 'classnames'
import { useState } from 'react'

import { CustomRadioProps } from '../types'

const CustomRadio = ({
  value,
  options,
  onChange,
  disabled,
  selectedValueColor,
}: CustomRadioProps) => {
  const [selected, setSelected] = useState<string | number>(value)

  return (
    <div
      className={classNames(
        'flex justify-between py-1',
        disabled ? 'cursor-not-allowed' : ''
      )}>
      {options.map((option, index) => (
        <div className='relative flex items-center justify-center py-1' key={index}>
          {selected === option.value ? (
            <div
              style={selected === option.value ? { color: selectedValueColor } : undefined}
              className={classNames(
                'center absolute top-[-12px] h-full align-top font-bold',
                selected === option.value ? `font-bold` : ''
              )}>
              .
            </div>
          ) : null}
          <span
            style={selected === option.value ? { color: selectedValueColor } : undefined}
            className={classNames(
              'px-2',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              selected === option.value ? `font-bold` : ''
            )}
            onClick={
              disabled
                ? () => undefined
                : () => {
                    setSelected(option.value)
                    onChange(option)
                  }
            }>
            {option.key}
          </span>
        </div>
      ))}
    </div>
  )
}

export { CustomRadio }
