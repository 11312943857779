import EditIcon from '@mui/icons-material/Edit'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'

import TextRender from '../../common/TextRender'
import { TTextDirection } from '../../common/types'
import { IAcceptedResponseProps } from '../types'
import MultimodalDisplay from './MultimodalDisplay'

const AcceptedResponse = ({
  prompt,
  textDirection,
  firstManualStepRunCreatedAt,
  config,
  onEditResponse,
  defaultRenderMode,
}: IAcceptedResponseProps) => (
  <div className='flex justify-start'>
    <Avatar
      sx={{
        height: '24px',
        width: '24px',
        marginRight: '16px',
        marginTop: 1,
      }}
    >
      <SmartToyIcon sx={{width: '16px'}} />
    </Avatar>
    <div className='flex-1 relative mt-2 mr-1 w-fit overflow-auto rounded bg-[#F5F5F7] py-1 px-2 text-sm'>
      <TextRender
        text={prompt?.acceptedResponse as string}
        renderMode={defaultRenderMode ?? 'markdown'}
        markdownProps={{
          components: {
            p: ({ children }) => <p className='whitespace-pre-wrap leading-relaxed'>{children}</p>,
          },
          className: 'overflow-auto',
          dir: textDirection as TTextDirection,
        }}
        plaintextProps={{
          className: 'my-3 overflow-auto',
        }}
        latexProps={{
          containerClassName: 'my-3 overflow-auto',
          className: 'whitespace-pre-wrap',
          textDirection: textDirection as TTextDirection,
        }}
      />
      <MultimodalDisplay content={prompt?.acceptedContent} />
    </div>
    {prompt?.createdAt > firstManualStepRunCreatedAt || config?.allowEditingImportedTurns ? (
      <div className='mr-2 mt-2 flex items-start'>
        <IconButton onClick={onEditResponse} className='text-gray-400'>
          <EditIcon />
        </IconButton>
      </div>
    ) : null}
  </div>
)

export default AcceptedResponse
