import { Wizard } from '@invisible/ultron/zod'
import { FC } from 'react'

import { ProcessAudioWac } from './audio/ProcessAudioWac'
import { RecordAudioWac } from './audio/RecordAudioWac'
import { BoltDocumentVerificationCriminalWAC } from './BoltDocumentVerificationCriminalWAC'
import { BoltDocumentVerificationIdWAC } from './BoltDocumentVerificationIdWAC'
import { BoltDocumentVerificationSelfieWAC } from './BoltDocumentVerificationSelfieWAC'
import { BoltDocumentVerificationSimpleWAC } from './BoltDocumentVerificationSimpleWAC'
import { ButtonWAC } from './ButtonWAC'
import { ChildBaseViewWAC } from './ChildBaseViewWAC'
import { CodeWAC2 } from './CodeWAC2'
import { ComboMonacoPDFWAC } from './ComboMonacoPDFWAC'
import { EmailFormWAC } from './EmailFormWAC'
import { EmbedWAC } from './EmbedWAC'
import { FormWAC } from './FormWAC'
import { ImageWAC } from './ImageWAC'
import { InformationBaseWAC } from './InformationBaseWAC'
import { InformationWACContainer } from './InformationWACContainer'
import { JSONWAC } from './JSONWAC'
import { KlarnaQAFormWAC } from './KlarnaQAFormWAC'
import { KlarnaScrapingFormWAC } from './KlarnaScrapingFormWAC'
import { MultimodalRLHFWAC } from './MultimodalRLHFWAC'
import { ProcessSecurityQuestionnaireWAC } from './ProcessSecurityQuestionnaireWAC'
import { QaWAC } from './QaWAC/index'
import { RankingWAC } from './RankingWAC'
import { RLHFWAC2 } from './RHLFWAC2'
import { RLHFWACPlus } from './RHLFWACPlus'
import { RLHFWACPlusMeta } from './RHLFWACPlusMeta'
import { RichTextWAC } from './RichTextWAC'
import { SFT2WAC } from './SFTWAC2/index'
import { SpellcheckWAC } from './SpellcheckWAC'
import { TableWAC } from './TableWAC'
import { TextAnnotationWAC } from './TextAnnotationWAC/index'
import { TextWAC } from './TextWAC'
import { ValidateButtonWAC } from './ValidateButtonWAC'
import { VideoWAC } from './VideoWAC'

// eslint-disable-next-line @typescript-eslint/ban-types
export const WizardAtomicComponents: { [key in Wizard.WACType.TSchema]?: FC<any> } = {
  text: TextWAC,
  richText: RichTextWAC,
  embed: EmbedWAC,
  form: FormWAC,
  childBaseView: ChildBaseViewWAC,
  submitButton: ButtonWAC,
  validateButton: ValidateButtonWAC,
  emailForm: EmailFormWAC,
  video: VideoWAC,
  code2: CodeWAC2,
  comboMonacoPDF: ComboMonacoPDFWAC,
  image: ImageWAC,
  information: InformationWACContainer,
  informationBase: InformationBaseWAC,
  JSON: JSONWAC,
  qa: QaWAC,
  SFT2: SFT2WAC,
  RLHF2: RLHFWAC2,
  RLHFPlus: RLHFWACPlus,
  MultimodalRLHF: MultimodalRLHFWAC,
  RLHFPlusMeta: RLHFWACPlusMeta,
  ranking: RankingWAC,
  spellcheck: SpellcheckWAC,
  table: TableWAC,
  textAnnotation: TextAnnotationWAC,
  recordAudio: RecordAudioWac,
  processAudio: ProcessAudioWac,

  // custom WAC's
  klarnaScrapingForm: KlarnaScrapingFormWAC,
  klarnaQAForm: KlarnaQAFormWAC,
  boltDocumentVerificationCriminalRecord: BoltDocumentVerificationCriminalWAC,
  boltDocumentVerificationId: BoltDocumentVerificationIdWAC,
  boltDocumentVerificationSelfie: BoltDocumentVerificationSelfieWAC,
  boltDocumentVerificationSimple: BoltDocumentVerificationSimpleWAC,
  processSecurityQuestionnaire: ProcessSecurityQuestionnaireWAC,
}
