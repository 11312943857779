import { values } from 'lodash/fp'
import {
  ChangeEvent,
  ElementType,
  FocusEvent,
  forwardRef,
  InputHTMLAttributes,
  ReactElement,
  Ref,
  useState,
} from 'react'
import { Box, BoxProps, Flex } from 'rebass'

import { getIcon, isIconName } from '../icons'
import { styled } from '../themes'

type TMaybeIconName = ReactElement | string

const maybeIcon = (s?: TMaybeIconName) => {
  if (!s) return null
  if (isIconName(s)) return getIcon(s)
  if (typeof s === 'string') return null
  return s as ReactElement
}

type IInput = InputHTMLAttributes<HTMLInputElement> & {
  marginLeft?: string
}
export const InputSize = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  OTHER: 'other',
} as const
const _Size = values(InputSize)
type TSize = typeof _Size[number]

const StyledInput = styled.input<IInput>`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 22px;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  background: transparent;
  color: #0A0810;
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  border: 0;
  width: 100%;
  outline: none;
  &:disabled {
    color: rgba(0, 0, 0, 0.25);
  }
`

const InputWrapper = styled.div<{
  disabled: boolean
  hasAddOnLeft: boolean
  hasAddOnRight: boolean
  size: TSize
  isValid: boolean
  isWide?: boolean
  hasMinWidth: boolean
}>`
  border: ${({ isValid }) => `1px solid ${isValid ? '#D0CFD2' : '#FF4D4F'}`};
  box-sizing: border-box;
  border-radius: ${({ hasAddOnLeft, hasAddOnRight }) => {
    if (hasAddOnLeft && hasAddOnRight) {
      return '0'
    }
    if (hasAddOnLeft) {
      return '0 6px 6px 0'
    }
    if (hasAddOnRight) {
      return ' 6px 0 0 6px'
    }
    return '6px'
  }};
  height: ${({ size }) =>
    size === InputSize.SMALL
      ? '24px'
      : size === InputSize.MEDIUM
      ? '32px'
      : size === InputSize.OTHER
      ? '32px'
      : '40px'};
  ${({ hasMinWidth, isWide }) => (hasMinWidth ? `min-width: ${isWide ? '475px' : '100px'};` : '')}
  padding: ${({ size }) =>
    size === InputSize.SMALL
      ? '0 12px'
      : size === InputSize.MEDIUM
      ? '5px 12px'
      : size === InputSize.OTHER
      ? '5px 2px'
      : '8px 12px'};
  display: flex;
  border-right: ${({ hasAddOnRight }) => (hasAddOnRight ? 'none' : '')};
  align-items: center;
  flex-grow: 1;
  &:focus-within {
    background: #FFFFFF;
    border: 1px solid '#604CA5';
    box-sizing: border-box;
    box-shadow: 0px 0px 0px 2px rgba(85, 175, 177, 0.1);
  }
  background: ${({ disabled }) => (disabled ? '#F3F2F3' : '')};
`

const IconWrapper = styled.div<{ disabled: boolean }>`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: gray;
  filter: ${({ disabled }) =>
    disabled
      ? 'invert(79%) sepia(8%) saturate(52%) hue-rotate(201deg) brightness(87%) contrast(90%)'
      : ''};
`

const AddOnLeftContainer = styled.div<{ disabled: boolean; size: TSize }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box;
  height: ${({ size }) =>
    size === InputSize.SMALL ? '24px' : size === InputSize.MEDIUM ? '32px' : '40px'};
  padding: 8px 12px;
  border-radius: 2px 0 0 2px;
  border: 1px solid #D0CFD2;
  border-right: none;
  background: #FAFAFB;
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.95)')};
`

const AddOnRightContainer = styled.div<{ disabled: boolean; size: TSize }>`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  box-sizing: border-box;
  height: ${({ size }) =>
    size === InputSize.SMALL ? '24px' : size === InputSize.MEDIUM ? '32px' : '40px'};
  padding: 8px 12px;
  border-radius: 0 2px 2px 0;
  border: 1px solid #D0CFD2;
  border-left: none;
  vertical-align: middle;
  color: ${({ disabled }) => (disabled ? 'rgba(0, 0, 0, 0.25)' : 'rgba(0, 0, 0, 0.95)')};
`

export type TInputType = 'text' | 'number' | 'url' | 'password' | 'tel' | 'date' | 'email' | 'file'

type InputComponent = Omit<BoxProps, 'prefix' | 'size' | 'onBlur'> & {
  id?: string
  value?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  prefix?: TMaybeIconName
  suffix?: TMaybeIconName
  type?: TInputType
  size?: TSize
  placeholder?: string
  disabled?: boolean
  pattern?: string
  required?: boolean
  addOnLeft?: TMaybeIconName
  addOnRight?: TMaybeIconName
  isWide?: boolean
  marginLeft?: string
  innerRef?: unknown
  className?: string
  hasMinWidth?: boolean
}

const Input = forwardRef(
  (
    {
      id,
      placeholder,
      type = 'text',
      disabled = false,
      onChange,
      onBlur,
      prefix,
      suffix,
      addOnLeft,
      addOnRight,
      size = 'medium',
      width,
      isWide = false,
      required = false,
      pattern,
      style,
      hasMinWidth = true,
      ...rest
    }: InputComponent,
    ref: Ref<typeof StyledInput>
  ) => {
    const ParsedLeft = maybeIcon(addOnLeft) as ElementType | string
    const ParsedRight = maybeIcon(addOnRight) as ElementType | string
    const ParsedPrefix = maybeIcon(prefix) as ElementType | string
    const ParsedSuffix = maybeIcon(suffix) as ElementType | string

    const [isValid, setIsValid] = useState(true)
    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setIsValid(e.target.validity.valid)
      if (onBlur) onBlur(e)
    }

    const handleWheel = (e: React.WheelEvent<HTMLInputElement>) => {
      if (type === 'number') {
        e.currentTarget.blur()
      }
    }

    return (
      <Flex width={width} {...rest} data-cy='inputFlex'>
        {addOnLeft && (
          <AddOnLeftContainer disabled={disabled} size={size}>
            {ParsedLeft
              ? [
                  <IconWrapper disabled={disabled}>
                    {isIconName(addOnLeft) ? <ParsedLeft /> : ParsedLeft}{' '}
                  </IconWrapper>,
                ]
              : addOnLeft}
          </AddOnLeftContainer>
        )}
        <InputWrapper
          hasMinWidth={hasMinWidth}
          disabled={disabled}
          hasAddOnLeft={Boolean(addOnLeft)}
          hasAddOnRight={Boolean(addOnRight)}
          size={size}
          isValid={isValid}
          isWide={isWide}
          className={'InputWrapper'}>
          {prefix && (
            <Box mr='8px'>
              <IconWrapper disabled={disabled}>
                {isIconName(prefix) ? <ParsedPrefix /> : ParsedPrefix}
              </IconWrapper>
            </Box>
          )}
          <StyledInput
            {...rest}
            style={style}
            ref={ref}
            type={type}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            required={required}
            pattern={pattern}
            onBlur={handleBlur}
            onWheel={handleWheel}
            className={'StyledInput'}
          />
          {suffix && (
            <Box ml='8px'>
              <IconWrapper disabled={disabled}>
                {isIconName(suffix) ? <ParsedSuffix /> : ParsedSuffix}
              </IconWrapper>
            </Box>
          )}
        </InputWrapper>
        {addOnRight && (
          <AddOnRightContainer disabled={disabled} size={size}>
            {ParsedRight
              ? [
                  <IconWrapper disabled={disabled}>
                    {isIconName(addOnRight) ? <ParsedRight /> : ParsedRight}{' '}
                  </IconWrapper>,
                ]
              : addOnRight}
          </AddOnRightContainer>
        )}
      </Flex>
    )
  }
)

export { Input }
