import { FileUploaderDropzone } from '@invisible/ui/file-uploader'
import { UploadedFile } from '@invisible/ui/file-uploader'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { Button, Stack, TextField } from '@mui/material'
import Avatar from '@mui/material/Avatar'

import MultimodalDisplay from '../components/MultimodalDisplay'
import { IContent, IPromptInputProps } from '../types'

export const PromptInput = ({
  promptText,
  attachedFiles,
  handleInputChange,
  setAttachedFiles,
  handlePromptSubmission,
  isDisabled,
  isReadOnly,
  loggedInUser,
  promptBucketName,
  promptBucketFolderPath,
  allowFileUpload,
}: IPromptInputProps) => {
  const handleAttachFile = (files: UploadedFile[]) => {
    // Convert files to IContent type
    const content: IContent[] = files.map((file) => ({
      type: `${file.type.split('/')[0]}_url`,
      url: file.url,
    }))

    setAttachedFiles(content)
  }

  return (
    <Stack
      direction='column'
      spacing={1}
      sx={{
        p: 1,
        bgcolor: 'white',
        borderTop: '2px solid',
        borderColor: 'grey.400',
        zIndex: 1,
        marginBottom: '0.75rem',
      }}>
      <Stack direction='row' spacing={2} alignItems='center'>
        <Avatar
          sx={{
            height: 20,
            width: 20,
          }}
          src={loggedInUser?.image || ''}
        />
        <TextField
          placeholder='Enter your prompt here...'
          value={promptText}
          onChange={handleInputChange}
          multiline
          fullWidth
          maxRows={15}
          minRows={1}
          disabled={isDisabled || isReadOnly}
          size='small'
        />
      </Stack>
      <Stack direction='row' spacing={2} alignItems='center'>
        {allowFileUpload && (
          <Stack
            direction='row'
            spacing={2}
            alignItems='center'
            sx={{
              width: '100%',
            }}>
            <FileUploaderDropzone
              bucketName={promptBucketName}
              directoryName={promptBucketFolderPath}
              saveUploadedFiles={handleAttachFile}
              uploadButtonIcon={AttachFileIcon}
              showFileCount={false}
              allowEmpty
              showMuiIconButton
            />
            <MultimodalDisplay content={attachedFiles} selectMode='modal' />
          </Stack>
        )}
        <Button
          variant='outlined'
          onClick={() => handlePromptSubmission(promptText, attachedFiles)}
          disabled={isDisabled || !promptText || isReadOnly}>
          Send
        </Button>
      </Stack>
    </Stack>
  )
}
