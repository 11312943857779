import { Skeleton } from '@invisible/ui/skeleton'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'

import TextRender from '../../common/TextRender'
import { TTextDirection } from '../../common/types'
import { useRLHFContext } from '../context'
import { IPromptEditProps } from '../types'
import { Metadata } from './Metadata'
import MultimodalDisplay from './MultimodalDisplay'

const PromptEdit = ({
  prompt: { id, text, index, createdAt, attachedFiles },
  baseRunVariables,
  disablePromptEdit,
  disableMetadataEdit,
  isLoadingBaseRunVariables,
  textDirection,
  handleClose,
  defaultRenderMode,
}: IPromptEditProps) => {
  const [promptText, setPromptText] = useState(text)
  const { config, updateBaseRunVariables, firstManualStepRunCreatedAt, isReadOnly } =
    useRLHFContext()

  const handleSavePrompt = async () => {
    await updateBaseRunVariables([
      {
        baseRunId: id,
        baseVariableId: config?.promptTextBaseVariableId as string,
        value: promptText,
      },
    ])
  }
  const editPromptTextEnabled =
    !disablePromptEdit &&
    (config.allowEditingImportedTurns || createdAt > firstManualStepRunCreatedAt)

  return (
    <div className='border-main border-t-solid border-t-2 bg-[#E0E7FF] p-3 mr-11'>
      <div className='flex items-center justify-between'>
        <div className='font-medium'>Prompt {index + 1}</div>
        <div className='flex gap-2'>
          {editPromptTextEnabled && (
            <IconButton disabled={text === promptText} onClick={() => handleSavePrompt()}>
              <CheckIcon />
            </IconButton>
          )}
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className='mt-4'>
        {isLoadingBaseRunVariables ? (
          <div className='my-4 flex flex-col justify-center gap-4'>
            <Skeleton.Rectangle height={40} width='100%' />
            <Skeleton.Rectangle height={40} width='100%' />
            <Skeleton.Rectangle height={40} width='100%' />
            <Skeleton.Rectangle height={40} width='100%' />
          </div>
        ) : (
          <Metadata
            isReadOnly={isReadOnly || disableMetadataEdit}
            data={config?.promptMetadata?.fields ?? []}
            baseRunId={id}
            baseRunVariables={baseRunVariables}
            updateBaseRunVariables={updateBaseRunVariables}
            selectedValueColor={'#000000'}
          />
        )}
      </div>
      {editPromptTextEnabled ? (
        <>
          <div
            className={
              'my-3 box-border inline-block w-full whitespace-pre-wrap  border-gray-300 bg-white p-2 outline-none'
            }
            contentEditable={true}
            onInput={(e) => setPromptText(e?.currentTarget?.textContent ?? '')}>
            {text}
          </div>
          <MultimodalDisplay content={attachedFiles} />
        </>
      ) : (
        <>
          <TextRender
            text={text as string}
            renderMode={defaultRenderMode ?? 'markdown'}
            markdownProps={{
              components: {
                p: ({ children }) => <p className='whitespace-pre-wrap leading-relaxed'>{children}</p>,
              },
              className: 'overflow-auto',
              dir: textDirection as TTextDirection,
            }}
            plaintextProps={{
              className: 'my-3 overflow-auto',
            }}
            latexProps={{
              containerClassName: 'my-3 overflow-auto',
              className: 'whitespace-pre-wrap',
              textDirection: textDirection as TTextDirection,
            }}
          />
          <MultimodalDisplay content={attachedFiles} />
        </>
      )}
    </div>
  )
}

export { PromptEdit }
