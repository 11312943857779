import Editor, { Monaco } from '@monaco-editor/react'
import { editor as MonacoEditor, IRange, Position } from 'monaco-editor'
import { useEffect, useRef, useState } from 'react'
import xmlFormat from 'xml-formatter'

import { Move, ScrollChangeData } from './types'
import { getScrollDirection } from './utils'

interface IForkedCodeWACProps {
  readOnly: boolean
  code: string
  onChangeMethod: (inputText: string) => Promise<void>
  move: undefined | Move
  setMove: (value: Move) => void
}

const editorOptions = (readOnly: boolean) => ({
  contextmenu: true,
  fontFamily: 'monospace',
  fontSize: 13,
  lineHeight: 24,
  hideCursorInOverviewRuler: true,
  minimap: {
    enabled: true,
  },
  scrollbar: {
    horizontalSliderSize: 10,
    verticalSliderSize: 10,
  },
  selectOnLineNumbers: true,
  roundedSelection: false,
  readOnly: readOnly,
  automaticLayout: true,
  stopRenderingLineAfter: 100000,
  maxTokenizationLineLength: 100000,
})

function removeXmlTagsRegex(xmlString: string): string {
  return xmlString.replace(/<[^>]*>/g, '').trim()
}

function getPositionFromMatches(
  matches: MonacoEditor.FindMatch[],
  currentPosition: Position | null,
  direction: 'up' | 'down'
): IRange | undefined {
  if (matches.length === 0 || currentPosition === null) return undefined
  const positions = matches.map((match) => match.range)
  if (direction === 'down') {
    return positions.find((position) => position.startLineNumber >= currentPosition.lineNumber)
  }
  positions.reverse()
  return positions.find((position) => position.startLineNumber <= currentPosition.lineNumber)
}

const ForkedCodeWAC = ({ readOnly, code, onChangeMethod, move, setMove }: IForkedCodeWACProps) => {
  const handleEditorChange = (value: string | undefined, editor: MonacoEditor.IStandaloneCodeEditor) => {
    if (!value || readOnly) return

    const model = editor.getModel()
    if (!model) return

    const position = editor.getPosition()
    if (!position) return

    const line = model.getLineContent(position.lineNumber)

    const charBeforeCursor = line[position.column - 2];
    if (charBeforeCursor === '>') {
      const beforeCursor = line.substring(0, position.column - 1)
      const lastOpenTag = beforeCursor.lastIndexOf('<')

      if (lastOpenTag !== -1) {
        const tagSection = beforeCursor.substring(lastOpenTag, beforeCursor.length - 1)
        if (tagSection.indexOf('<', 1) !== -1) return
        const tagContent = tagSection.substring(1)
        if (/^[a-zA-Z0-9_.:-]+$/.test(tagContent)) {
          const currentPosition = editor.getPosition()

          editor.executeEdits('auto-close-tag', [{
            range: new monacoRef.current!.Range(
              position.lineNumber,
              position.column,
              position.lineNumber,
              position.column
            ),
            text: `</${tagContent}>`
          }])

          if (currentPosition) {
            editor.setPosition(currentPosition)
          }
        }
      }
    }
  }
  const monacoRef = useRef<Monaco | null>(null)
  const editorRef = useRef<MonacoEditor.IStandaloneCodeEditor | null>(null)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  useEffect(() => {
    if (move?.source === 'pdf' && !isHovered) {
      const editor = editorRef.current
      if (!editor) return

      const model = editor.getModel()
      if (!model || !move.text) return

      const matches = model.findMatches(move.text, false, false, false, null, true)
      if (['up', 'down'].includes(move.direction)) {
        const position = getPositionFromMatches(
          matches,
          editor.getPosition(),
          move.direction as 'up' | 'down'
        )
        if (position) {
          setPosition(position.startLineNumber, position.startColumn)
        }
      }
    }
  }, [move, isHovered])

  const setPosition = (row: number, column: number) => {
    const editor = editorRef.current
    if (!editor) return
    editor.setPosition({ lineNumber: row, column })
    editor.revealLineInCenter(row)
  }

  const handleEditorDidMount = (editor: MonacoEditor.IStandaloneCodeEditor, monaco: Monaco) => {
    monacoRef.current = monaco
    editorRef.current = editor

    const container = editor.getDomNode()
    if (container) {
      container.addEventListener('mouseenter', () => setIsHovered(true))
      container.addEventListener('mouseleave', () => setIsHovered(false))
    }


    editor.onDidScrollChange((scroll) => {
      const direction = getScrollDirection(scroll as ScrollChangeData)
      const visibleRange = editor.getVisibleRanges()[0]
      const model = editor.getModel()

      if (!model || !visibleRange) return

      const totalLines = model.getLineCount()
      let startLine = visibleRange.startLineNumber
      let aggregatedClean = ''

      while (aggregatedClean.length < 100 && startLine <= totalLines) {
        const lineContent = model.getLineContent(startLine)
        const cleanLine = removeXmlTagsRegex(lineContent)
        aggregatedClean += cleanLine
        startLine++
      }

      if (aggregatedClean.length > 0) {
        setMove({ text: aggregatedClean, source: 'monaco', direction })
      }
    })
  }

  let cleanCode
  try {
    cleanCode = xmlFormat(code || '', { indentation: '  ' })
  } catch {
    cleanCode = code || ''
  }

  return (
    <Editor
      height='100%'
      width='100%'
      theme='vs-light'
      language='XML'
      value={cleanCode}
      options={editorOptions(readOnly)}
      onChange={(value) => {
        onChangeMethod(value || '')
        if (editorRef.current) {
          handleEditorChange(value, editorRef.current)
        }
      }}
      onMount={handleEditorDidMount}
    />
  )
}

export { ForkedCodeWAC }
