import { IConversationAreaProps } from '../types'
import { ResponseLoading } from './ResponseLoading'
import { Turn } from './Turn'

export const ConversationArea = ({
  prompts,
  isFetchingResponses,
  isCreatingBaseRun,
  isCreatingManyBaseRuns,
  isLastResponseEmpty,
  collapseAll,
  loggedInUser,
  defaultRenderMode,
}: IConversationAreaProps) => (
  <div>
    {prompts?.map((prompt, index) => (
      <Turn
        key={index}
        prompt={{ ...prompt, index }}
        collapsed={collapseAll}
        loggedInUser={loggedInUser}
        defaultRenderMode={defaultRenderMode}
      />
    ))}
    {(isFetchingResponses || isCreatingBaseRun || isCreatingManyBaseRuns) && (
      <ResponseLoading />
    )}
    {isLastResponseEmpty && (
      <div className='text-paragraphs py-2'>
        Blank response from Model. You can refresh the page & retry or End Conversation.
      </div>
    )}
  </div>
)
