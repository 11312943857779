import { theme } from '@invisible/ui/mui-theme-v2'
import { SvgIconComponent } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import Button, { ButtonPropsSizeOverrides, ButtonPropsVariantOverrides } from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import { OverridableStringUnion } from '@mui/types'
import axios from 'axios'
import { get } from 'lodash/fp'
import pMap from 'p-map'
import { createElement, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import * as uuid from 'uuid'

import { DeleteFilledIcon, FileOutlineIcon } from '../icons'
import { Icons } from '../icons'
import { LogoSpinner } from '../logo-spinner'
import { Modal } from '../modal'
import { Tooltip } from '../tooltip'
import { GOOGLE_CLOUD_BUCKET_NAME } from './config/env'

const GOOGLE_CLOUD_API = 'https://storage.googleapis.com'
const GOOGLE_CLOUD_PRIVATE_API = 'https://storage.cloud.google.com' // for authenticated requests

type ErrorCardProps = {
  message: string
  visible?: boolean
  deleteCard: () => void
}

type InfoCardProps = {
  name: string
  deleteFile: () => void
}

type IUploaderDropzoneProps = {
  acceptedFileTypes?: string | string[]
  directoryName: string
  saveUploadedFiles: (files: UploadedFile[]) => void
  setFileCount?: Dispatch<SetStateAction<number>>
  maxUploadAllowed?: number
  disabled?: boolean
  defaultValues?: string[]
  bucketName?: string
  setShowModal: Dispatch<SetStateAction<boolean>>
  uploadedFiles: UploadedFile[]
  setUploadedFiles: Dispatch<SetStateAction<UploadedFile[]>>
  allowEmpty?: boolean
}

type IUploadModal = Omit<IUploaderDropzoneProps, 'setUploadedFiles' | 'uploadedFiles'> & {
  showModal?: boolean
}

type IFileUploaderDropzone = {
  acceptedFileTypes?: string | string[]
  directoryName: string
  saveUploadedFiles: (files: UploadedFile[]) => void
  showFileCount?: boolean
  uploadButtonSize?:
    | OverridableStringUnion<'medium' | 'small' | 'large', ButtonPropsSizeOverrides>
    | undefined
  uploadButtonType?:
    | OverridableStringUnion<'text' | 'outlined' | 'contained', ButtonPropsVariantOverrides>
    | undefined
  maxUploadAllowed?: number
  disabled?: boolean
  defaultValues?: string[]
  bucketName?: string
  uploadButtonIcon?: keyof typeof Icons | SvgIconComponent
  showMuiIconButton?: boolean
  allowEmpty?: boolean
}

export type UploadedFile = {
  fileUuid: string
  fileName: string
  url: string
  size: number
  type: string
  filePath: string
}

const ErrorCard = ({ message, deleteCard }: ErrorCardProps) => (
  <div className='space-y-2 rounded-md border border-solid border-red-200 bg-red-100 p-2'>
    <div className='flex items-center justify-between'>
      <p className='font-bold text-red-600'>Upload Failed</p>
      <DeleteFilledIcon
        width={15}
        height={15}
        className='cursor-pointer text-gray-400 hover:text-gray-600'
        onClick={() => deleteCard()}
      />
    </div>

    <span>{message}</span>
  </div>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const InfoCard = ({ name, deleteFile }: InfoCardProps) => (
  <div className='flex items-center justify-between rounded-md p-2 hover:border hover:border-solid hover:border-gray-200 hover:bg-gray-100'>
    <span className='w-[80%]'>{name}</span>

    <DeleteFilledIcon
      width={15}
      height={15}
      className='cursor-pointer text-gray-400 hover:text-gray-600'
      onClick={() => deleteFile()}
    />
  </div>
)

export const UploaderDropzone = ({
  acceptedFileTypes,
  directoryName,
  bucketName,
  maxUploadAllowed,
  defaultValues,
  setUploadedFiles,
  uploadedFiles,
  allowEmpty,
}: IUploaderDropzoneProps) => {
  const [rejections, setRejections] = useState<string[]>([])

  const [uploading, setUploading] = useState(false)

  const fileReader = async (file: File) => {
    const reader = new FileReader()

    return new Promise((resolve, reject) => {
      reader.onabort = () => reject(new Error('File reading was aborted'))
      reader.onerror = () => new Error('An error occurred while reading the file')
      reader.onload = () => {
        const binaryStr = reader.result
        resolve(binaryStr)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  const uploadFiles = async (files: File[], directoryName?: string) => {
    await pMap(
      files,
      async (file) => {
        await fileReader(file).then(async () => {
          try {
            const fileName = encodeURIComponent(file.name.trim().replace(/\s+/g, '_')) // Replace all whitespaces with underscores

            const fileUuid = uuid.v4()
            const fileType = file?.name?.toLowerCase()?.endsWith('.jsonl')
              ? 'application/octet-stream'
              : file?.type

            const GOOGLE_API =
              bucketName && bucketName.includes('private')
                ? GOOGLE_CLOUD_PRIVATE_API
                : GOOGLE_CLOUD_API

            const url = `${GOOGLE_API}/${
              bucketName ?? GOOGLE_CLOUD_BUCKET_NAME
            }/${directoryName}/${fileUuid}${fileName}`

            const signedUrl = await axios.post(
              `/api/google/cloud/signedUrl?file=${directoryName}/${fileUuid}${fileName}&type=${fileType}&bucketName=${
                bucketName ?? GOOGLE_CLOUD_BUCKET_NAME
              }`
            )

            await axios.put(get('url', signedUrl.data), file, {
              headers: { 'Content-Type': fileType },
            })

            const filePath = `gs://${
              bucketName ?? GOOGLE_CLOUD_BUCKET_NAME
            }/${directoryName}/${fileUuid}${fileName}`

            setUploadedFiles((prev) => [
              ...prev,
              { fileName, url, size: file.size, type: fileType, fileUuid, filePath },
            ])
          } catch (err: any) {
            const errorMessage = err.response?.data?.errorMessage || err.message
            setRejections((prev) => [
              ...prev,
              `An error ocurred while uploading ${file.name}`,
              errorMessage,
            ])
          }
        })
      },
      { concurrency: 1 }
    )

    setUploading(false)
  }

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setUploading(true)

    fileRejections.forEach((rejection) => {
      setRejections((prev) => [...prev, `${rejection.file.name} ${rejection.errors[0].message}`])
    })

    await uploadFiles(acceptedFiles, directoryName)
  }, [])

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: acceptedFileTypes,
    maxFiles: maxUploadAllowed,
    onDrop,
    noClick: true,
  })

  useEffect(
    () => () => {
      setRejections([])
      if (defaultValues?.length === 0) {
        setUploadedFiles([])
      }
    },
    []
  )

  return (
    <div className='min-w-[400px] max-w-[600px]'>
      <div className='h-[425px] space-y-2 overflow-y-auto overflow-x-hidden p-4'>
        <div
          {...getRootProps()}
          className='flex h-[225px] w-full items-center justify-center rounded-md border border-dashed border-gray-200 bg-gray-100'>
          <div className='space-y-2'>
            <input hidden {...getInputProps()} />
            {isDragActive ? (
              <p>Drop it like it&apos; hot!</p>
            ) : (
              <>
                <div className='flex justify-center'>
                  <FileOutlineIcon width={20} height={20} className='self-center' />
                </div>
                <p>Drop file(s) to upload</p>
                <p className='text-center'>OR</p>
                <div className='flex w-full justify-center'>
                  <Button size='small' variant='text' onClick={open}>
                    Browse
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
        {uploading ? (
          <div className='flex items-center justify-center gap-2'>
            <LogoSpinner width={25} height={25} />
            <p>Uploading...</p>
          </div>
        ) : null}

        {rejections?.map((error, i) => (
          <ErrorCard
            message={error}
            deleteCard={() => setRejections(rejections.filter((error, index) => index !== i))}
          />
        ))}
        {uploadedFiles?.map((file, i) => (
          <InfoCard
            key={i}
            name={`${file.fileName}`}
            deleteFile={() => setUploadedFiles(uploadedFiles.filter((file, index) => index !== i))}
          />
        ))}
      </div>
    </div>
  )
}

const UploadModal = ({
  showModal,
  setShowModal,
  defaultValues,
  saveUploadedFiles,
  setFileCount,
  allowEmpty,
  ...props
}: IUploadModal) => {
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])
  useEffect(() => {
    if (defaultValues && defaultValues?.length > 0) {
      const defaultUploadedFiles = defaultValues?.map((value) => ({
        fileName: value.substring(value.lastIndexOf('/') + 1),
        url: value,
        size: 0,
        type: 'image/jpeg',
        filePath: value,
      }))
      setUploadedFiles(defaultUploadedFiles as UploadedFile[])
    }
  }, [defaultValues])

  return showModal ? (
    <Modal
      visible={showModal}
      onClose={() => setShowModal(false)}
      title='Upload File(s)'
      primaryButton={
        <Button
          variant='outlined'
          size='medium'
          disabled={uploadedFiles.length === 0 && !allowEmpty}
          onClick={() => {
            saveUploadedFiles(uploadedFiles)
            if (setFileCount) setFileCount(uploadedFiles.length)
            setShowModal(false)
          }}>
          Save
        </Button>
      }
      secondaryButton={
        <Button variant='text' size='medium' onClick={() => setShowModal(false)}>
          Cancel
        </Button>
      }>
      <UploaderDropzone
        {...props}
        saveUploadedFiles={saveUploadedFiles}
        setShowModal={setShowModal}
        setUploadedFiles={setUploadedFiles}
        uploadedFiles={uploadedFiles}
      />
    </Modal>
  ) : null
}

const FileUploaderDropzone = ({
  acceptedFileTypes,
  directoryName,
  bucketName,
  saveUploadedFiles,
  showFileCount = true,
  uploadButtonSize = 'small',
  uploadButtonType = 'outlined',
  maxUploadAllowed,
  disabled = false,
  defaultValues = [],
  uploadButtonIcon,
  showMuiIconButton = false,
  allowEmpty = false,
}: IFileUploaderDropzone) => {
  const [showModal, setShowModal] = useState(false)
  const [fileCount, setFileCount] = useState(0)

  const defaultFiles: string[] = []

  if (defaultValues.length > 0) {
    // eslint-disable-next-line array-callback-return
    defaultValues.map((value) => {
      if (value.length > 0) {
        defaultFiles.push(value)
      }
    })
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <div className='flex items-center gap-2'>
          {showMuiIconButton ? (
            <Tooltip arrow side='top' content={'Upload Attachments'}>
              <IconButton onClick={() => setShowModal(true)} size={uploadButtonSize}>
                {uploadButtonIcon ?
                  createElement(uploadButtonIcon, { fontSize: uploadButtonSize }) :
                  <AddIcon fontSize={uploadButtonSize} />}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip arrow side='top' content={'Upload Attachments'}>
              <Button
                size={uploadButtonSize}
                variant={uploadButtonType}
                startIcon={uploadButtonIcon}
                onClick={() => setShowModal(true)}
                disabled={disabled}>
                Upload
              </Button>
            </Tooltip>
          )}

          {showFileCount && fileCount > 0 ? (
            <span>
              {fileCount} file{fileCount > 1 ? 's' : ''} uploaded
            </span>
          ) : null}
        </div>

        <UploadModal
          bucketName={bucketName}
          showModal={showModal}
          setShowModal={setShowModal}
          acceptedFileTypes={acceptedFileTypes}
          directoryName={directoryName}
          saveUploadedFiles={saveUploadedFiles}
          setFileCount={setFileCount}
          maxUploadAllowed={maxUploadAllowed}
          defaultValues={defaultFiles}
          allowEmpty={allowEmpty}
        />
      </ThemeProvider>
    </div>
  )
}

export { FileUploaderDropzone }
