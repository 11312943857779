import { UuidSchema, z } from '@invisible/zod'

import { FormulaArgumentTypesSchema, FormulaValueTypesSchema } from '../../constants'
import { schema as formFieldTypeSchema } from './FormFieldType'

const operandSchema = z.object({
  value: z.string(),
  type: FormulaArgumentTypesSchema,
  valueType: FormulaValueTypesSchema,
  baseVariableId: UuidSchema.optional(),
})

const operatorEnum = z.enum(['eq', 'neq', 'isNull', 'isNotNull', 'gt', 'lt'])

const conditionSchema = z.object({
  leftOperand: operandSchema,
  rightOperand: operandSchema.optional(),
  operator: operatorEnum,
})

const conditionGoToSchema = z.object({
  condition: conditionSchema,
  goToSection: UuidSchema.optional(),
  goToSectionAndSubmit: z.boolean().optional(),
  id: UuidSchema,
})

const schema = z.object({
  id: UuidSchema.optional(),
  sectionId: UuidSchema.optional(),
  label: z.string().optional(),
  isConditionalInput: z.boolean().optional(),
  note: z.string().optional(),
  type: formFieldTypeSchema.or(z.string()),
  stepTemplateVariableId: UuidSchema.optional(),
  baseVariableId: UuidSchema.optional(),
  baseId: UuidSchema.optional(),
  required: z.boolean().optional(),
  editable: z.boolean().optional(),
  placeholder: z.string().optional(),
  options: z
    .array(
      z.object({
        key: z.string(),
        value: z.union([z.string(), z.number()]),
        goToSection: UuidSchema.optional(),
        goToSectionAndSubmit: z.boolean().optional(),
      })
    )
    .optional(),
  goToSectionWhenUnset: UuidSchema.optional(),
  goToSectionWhenUnsetAndSubmit: z.boolean().optional(),
  goToSectionWhenTrue: UuidSchema.optional(),
  goToSectionWhenTrueAndSubmit: z.boolean().optional(),
  goToSectionWhenFalse: UuidSchema.optional(),
  goToSectionWhenFalseAndSubmit: z.boolean().optional(),
  dimensions: z
    .object({ labelWidth: z.string().optional(), contentWidth: z.string().optional() })
    .optional(),
  uploadMeta: z
    .object({
      acceptedFiles: z.array(z.string()).optional(),
      directoryName: z.string().optional(),
      bucketName: z.string().optional(),
      outputFormat: z.string().optional(),
      linkFormat: z.string().optional(),
    })
    .optional(),
  isConditionalWithFormula: z.boolean().optional(),
  conditionGoTos: z.array(conditionGoToSchema).optional(),
  min: z.number().optional(),
  max: z.number().optional(),
  isValid: z.boolean().optional(),
  characterLimit: z.number().optional(),
  characterLimitType: z.enum(['hard', 'soft']).optional(),
  wordLimit: z.number().optional(),
  wordLimitType: z.enum(['hard', 'soft']).optional(),
})

type TSchema = z.infer<typeof schema>
type TCondition = z.infer<typeof conditionSchema>
type TConditionGoTo = z.infer<typeof conditionGoToSchema>
type TOperand = z.infer<typeof operandSchema>
type TOperator = z.infer<typeof operatorEnum>

export { schema }
export type { TCondition, TConditionGoTo, TOperand, TOperator, TSchema }
