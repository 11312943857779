import { IResponseSelectionProps } from '../types'
import Response from './Response'

const ResponseSelection = ({
  response,
  index,
  prompt,
  normalizedActivePromptResponses,
  highestRankedResponseIds,
  setIsEditingResponse,
  stepRunId,
  config,
  isLastTurn,
  defaultRenderMode,
}: IResponseSelectionProps) => {
  const responseData = { ...response, index: index + 1 }
  const numOfRank = normalizedActivePromptResponses.flattened.length
  const isHighestRanked = highestRankedResponseIds.includes(response.id)
  const disableResponseMetadataEdit = !config?.allowResponseMetadataEditing && !isLastTurn
  const disableSelectionAndResponseEdit = config?.allowEditingPreviousTurns ? false : !isLastTurn

  return (
    <div className='min-w-[300px] flex-1 mr-2'>
      <Response
        response={responseData}
        activePromptId={prompt.id}
        activePromptCreatedAt={prompt.createdAt}
        numOfRank={numOfRank}
        key={response.model + response.id}
        stepRunId={stepRunId}
        baseRunVariables={response.baseRunVariables ?? []}
        isHighestRanked={isHighestRanked}
        selectedResponseId={prompt.responseId}
        disableResponseMetadataEdit={disableResponseMetadataEdit}
        disableSelectionAndResponseEdit={disableSelectionAndResponseEdit}
        handleClose={() => setIsEditingResponse(false)}
        defaultRenderMode={defaultRenderMode}
      />
    </div>
)}

export default ResponseSelection
