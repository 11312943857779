import { useWizardState } from '@invisible/common/components/providers/active-wizard-provider'
import { sendErrorToSentry } from '@invisible/errors'
import { logger } from '@invisible/logger/client'
import { useContext } from '@invisible/trpc/client'
import { FileUploaderDropzone, UploadedFile } from '@invisible/ui/file-uploader'
import { Input } from '@invisible/ui/input'
import { theme } from '@invisible/ui/mui-theme-v2'
import { QuillEditor } from '@invisible/ui/quill-editor'
import { TagInput } from '@invisible/ui/tag-input'
import { Text } from '@invisible/ui/text'
import { gray, styled } from '@invisible/ui/themes'
import { useToasts } from '@invisible/ui/toasts'
import { inferQueryOutput } from '@invisible/ultron/trpc/server'
import { Wizard as WizardSchemas } from '@invisible/ultron/zod'
import { CircularProgress } from '@mui/material'
import Button from '@mui/material/Button'
import { ThemeProvider } from '@mui/material/styles'
import axios from 'axios'
import { ChangeEvent, useState } from 'react'
import { useQueryClient } from 'react-query'

import { useCompleteStepRun } from '../hooks/useCompleteStepRun'
import { EmailWacAttachment } from './EmailWacAttachment'

const Container = styled.div`
  border-radius: 8px;
  height: 100%;
  background-color: white;
  border: 1px solid ${gray(4)};
  padding: 10px;
  overflow: auto;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.024) 0px 2px 4px;
`

type IProps = WizardSchemas.WACConfig.TSchema & {
  stepRun: TStepRun
  isReadOnly: boolean
}

type TStepRun = NonNullable<inferQueryOutput<'stepRun.findById'>>

const EmailFormWAC = ({ emailForm, stepRun, isReadOnly }: IProps) => {
  const [emailText, setEmailText] = useState(emailForm?.body ?? '')
  const [emailSubject, setEmailSubject] = useState(emailForm?.subject ?? '')
  const [toEmails, setToEmails] = useState<string[]>(emailForm?.to ?? [])
  const [ccEmails, setCcEmails] = useState<string[]>(emailForm?.cc ?? [])
  const [bccEmails, setBccEmails] = useState<string[]>(emailForm?.bcc ?? [])
  const [emailAttachments, setEmailAttachments] = useState<UploadedFile[]>([])
  const reactQueryContext = useContext()
  const { addToast } = useToasts()
  const { dispatch } = useWizardState()
  const reactQueryClient = useQueryClient()

  const { mutateAsync: markStepRunDone, isLoading: isMarkStepRunDoneLoading } = useCompleteStepRun({
    onSettled: () => {
      reactQueryClient.invalidateQueries('get-base-runs')
      reactQueryClient.invalidateQueries('UserActiveWorkingProcessIDs')
      reactQueryContext.invalidateQueries('stepRun.findAssignedToMe')
      reactQueryContext.invalidateQueries('stepRun.findCompletedAssignedToMe')
    },
  })

  const removeFile = async (attachment: UploadedFile) => {
    setEmailAttachments(emailAttachments.filter((a) => a.fileName !== attachment.fileName))
  }

  const constructAttachmentForEmail = (files: UploadedFile[]) => {
    const size = files.reduce((prev, curr) => prev + curr.size, 0)
    const content = files.map((file) => ({
      name: file.fileName,
      type: file.type,
      size: file.size,
      url: file.url,
    }))
    return { size, content }
  }

  const handleEmailSend = async () => {
    const email = {
      to: toEmails.map((e) => ({ email: e, name: '' })),
      cc: ccEmails.map((e) => ({ email: e, name: '' })),
      bcc: bccEmails.map((e) => ({ email: e, name: '' })),
      assistant: emailForm?.dalAssistant,
      subject: emailSubject,
      body: emailText,
      ...(emailAttachments && {
        attachment: constructAttachmentForEmail(emailAttachments),
      }),
    }

    try {
      logger.info('EmailFormWAC - Calling /api/malone/send-email', { email })

      await axios.post(`/api/malone/send-email`, {
        ...email,
      })

      if (emailForm?.completeStepRunOnSubmit) {
        await markStepRunDone({ id: stepRun.id })
        dispatch({ type: 'closeWizard' })
      } else {
        addToast(`Email successfully sent`, {
          appearance: 'success',
        })
      }
    } catch (e: any) {
      sendErrorToSentry(e)
      logger.error(`EmailFormWAC - Email failed to send: ${e?.message}`, { e })
      addToast(`Email failed to send: ${e?.message}`, {
        appearance: 'error',
      })
    }
  }

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <div className='mb-3 flex items-center'>
          <Text width='70px' textAlign='right' mr='10px'>
            To:
          </Text>
          <TagInput
            disabled={isReadOnly}
            tags={toEmails}
            onChange={(tags) => setToEmails(tags)}
            width={350}
          />
        </div>
        <div className='mb-3 flex items-center'>
          <Text width='70px' textAlign='right' mr='10px'>
            Cc:
          </Text>
          <TagInput
            disabled={isReadOnly}
            tags={ccEmails}
            onChange={(tags) => setCcEmails(tags)}
            width={350}
          />
        </div>
        <div className='mb-3 flex items-center'>
          <Text width='70px' textAlign='right' mr='10px'>
            Bcc:
          </Text>
          <TagInput
            disabled={isReadOnly}
            tags={bccEmails}
            onChange={(tags) => setBccEmails(tags)}
            width={350}
          />
        </div>
        <div className='mb-3 flex items-center'>
          <Text width='70px' textAlign='right' mr='10px'>
            Subject:
          </Text>
          <Input
            width='350px'
            disabled={isReadOnly}
            value={emailSubject}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmailSubject(e.target.value)}
          />
        </div>

        <div className='mt-12 w-full'>
          <QuillEditor
            value={emailText}
            readOnly={isReadOnly}
            onChange={(text) => setEmailText(text)}
            width='100%'
            margin='0 20px'
          />
        </div>

        {emailAttachments ? (
          <EmailWacAttachment removeFile={removeFile} attachments={emailAttachments} />
        ) : null}

        <div className='absolute bottom-4 right-1 mb-0 mt-5 flex justify-end'>
          <div className='mr-4'>
            <FileUploaderDropzone
              saveUploadedFiles={(files) => setEmailAttachments([...emailAttachments, ...files])}
              directoryName='email-attachment-upload'
              uploadButtonSize='medium'
              uploadButtonType='text'
              showFileCount={false}
              disabled={isReadOnly}
            />
          </div>
          <div className='mr-10'>
            <Button
              variant='outlined'
              color='primary'
              onClick={handleEmailSend}
              disabled={toEmails.length === 0 || isReadOnly}>
              {isMarkStepRunDoneLoading ? <CircularProgress color='inherit' size={20} /> : 'Send'}
            </Button>
          </div>
        </div>
      </ThemeProvider>
    </Container>
  )
}

export { EmailFormWAC }
