import { logger } from '@invisible/logger/client'
import AudioFileIcon from '@mui/icons-material/AudioFile'
import CloseIcon from '@mui/icons-material/Close'
import DescriptionIcon from '@mui/icons-material/Description'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import {
  Box,
  Button,
  CardMedia,
  CardMediaProps,
  CircularProgress,
  IconButton,
  IconButtonProps,
  Modal,
  Stack,
  Typography
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'

import AudioPlayer from '../sub-components/AudioPlayer'
import { IContent } from '../types'

type SelectMode = 'expand' | 'modal'

interface ThumbnailProps {
  type: string
  url: string
  className?: string
}

const Thumbnail = ({ type, url, className }: ThumbnailProps) => {
  const [isLoading, setIsLoading] = useState(true)

  if (type === 'image_url') {
    return (
      <Box className={className} position="relative">
        {isLoading && (
          <Box position="absolute" display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
            <CircularProgress size={24} />
          </Box>
        )}
        <CardMedia
          component='img'
          image={url}
          alt='Response'
          sx={{ width: '100%' }}
          onLoad={() => setIsLoading(false)}
        />
      </Box>
    )
  }

  let fileIcon = <DescriptionIcon style={{ fontSize: 48 }} />

  if (type === 'audio_url') {
    fileIcon = <AudioFileIcon style={{ fontSize: 48 }} />
  }

  return (
    <Stack alignItems='center'>
      {fileIcon}
      <Typography
        variant='body2'
        color='textSecondary'
        noWrap
        sx={{ width: '100%', textAlign: 'left' }}>
        {url.split('/').pop()}
      </Typography>
    </Stack>
  )
}

interface FileDisplayProps {
  type: string
  url: string
}

const StyledImageBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  height: '100%',
  width: 'fit-content',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledCardMedia = styled(CardMedia)<CardMediaProps<'img'>>(({ theme }) => ({
  maxHeight: '50vh',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.grey[400]}`,
  objectFit: 'contain',
}))

const StyledFileBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.grey[400]}`,
  maxHeight: '400px',
  width: 'fit-content',
  overflow: 'hidden',
  backgroundColor: theme.palette.grey[100],
}))

const FileDisplay = ({ type, url }: FileDisplayProps) => {
  const [isLoading, setIsLoading] = useState(true)

  if (type === 'image_url') {
    return (
      <StyledImageBox>
        {isLoading && (
          <Box position="absolute" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        <StyledCardMedia
          component="img"
          image={url}
          alt={url}
          onLoad={() => setIsLoading(false)}
        />
      </StyledImageBox>
    )
  }

  if (type === 'audio_url') {
    return <AudioPlayer url={url} />
  }

  return (
    <StyledFileBox>
      <Stack alignItems='center' p={2}>
        <Typography variant='body2' color='textSecondary' noWrap mt={0}>
          Trouble displaying file, click to view
        </Typography>
        <Box mt={1}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => window.open(url, '_blank')}
          >
            View
          </Button>
        </Box>
      </Stack>
    </StyledFileBox>
  )
}

interface ModalDisplayProps {
  open: boolean
  onClose: () => void
  content: IContent
  onNext?: () => void
  onPrev?: () => void
  showNavigation?: boolean
}

const StyledModalDisplay = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledModalDisplayBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '80vh',
  width: '80vw',
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

interface ModalNavigationButtonProps extends IconButtonProps {
  left?: boolean
  right?: boolean
}

const StyledModalDisplayNavigationButton = styled(IconButton)<ModalNavigationButtonProps>(({ theme, left, right }) => ({
  position: 'absolute',
  left: left ? theme.spacing(1) : 'auto',
  right: right ? theme.spacing(1) : 'auto',
  top: '50%',
  transform: 'translateY(-50%)'
}))

const StyledCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[700],
}))

const ModalDisplay = ({
  open,
  onClose,
  content,
  onNext,
  onPrev,
  showNavigation = true
}: ModalDisplayProps) => (
  <StyledModalDisplay
    open={open}
    onClose={onClose}
  >
    <StyledModalDisplayBox>
      <StyledCloseButton onClick={onClose}>
        <CloseIcon />
      </StyledCloseButton>
      {showNavigation && (
        <>
          <StyledModalDisplayNavigationButton onClick={onPrev} left>
            <NavigateBeforeIcon />
          </StyledModalDisplayNavigationButton>
          <StyledModalDisplayNavigationButton onClick={onNext} right>
            <NavigateNextIcon />
          </StyledModalDisplayNavigationButton>
        </>
      )}
      <FileDisplay type={content.type} url={content.url} />
    </StyledModalDisplayBox>
  </StyledModalDisplay>
)

interface MultimodalDisplayProps {
  content?: IContent[] | string
  allowSelection?: boolean
  selectMode?: SelectMode
}

const MultimodalDisplay = ({ content, allowSelection = true, selectMode = 'expand' }: MultimodalDisplayProps) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null)
  const [parsedContent, setParsedContent] = useState<IContent[]>([])

  useEffect(() => {
    if (!content) {
      setParsedContent([])
      return
    }

    if (typeof content === 'string') {
      try {
        setParsedContent(JSON.parse(content))
      } catch (e: unknown) {
        logger.error('Failed to parse content in MultimodalDisplay:', e as Error)
        setParsedContent([])
      }
    } else {
      setParsedContent(content)
    }
  }, [content])

  const handleClick = (index: number) => {
    if (allowSelection) {
      if (selectedItem === index) {
        setSelectedItem(null)
      } else {
        setSelectedItem(index)
      }
    }
  }

  return (
    <Stack>
      <Box sx={{ display: 'flex' }}>
        {parsedContent.map((item, index) => {
          const outlineColor = selectedItem === index ? 'var(--color-theme-weak)' : 'transparent'
          return (
            <Box
              key={`thumbnail-${index}`}
              sx={{
                m: 1,
                borderRadius: 1,
                border: 2,
                borderColor: 'grey.200',
                width: 64,
                height: 64,
                overflow: 'hidden',
                outline: selectedItem === index ? 2 : 0,
                outlineColor: outlineColor,
                cursor: allowSelection ? 'pointer' : 'default',
              }}
              onClick={() => handleClick(index)}>
              <Thumbnail type={item.type} url={item.url} />
            </Box>
          )
        })}
      </Box>
      <Box>
        {parsedContent.map((item, index) => {
          if (!allowSelection) {
            return null
          }

          if (selectMode === 'expand') {
            return (
              <Box
                key={`file-display-${index}`}
                display={selectedItem === index ? 'block' : 'none'}
              >
                <FileDisplay type={item.type} url={item.url} />
              </Box>
            )
          }

          return (
            <ModalDisplay
              key={`modal-display-${index}`}
              open={selectedItem === index}
              onClose={() => setSelectedItem(null)}
              content={item}
              onNext={() => setSelectedItem((selectedItem || 0) + 1 >= parsedContent.length ? 0 : (selectedItem || 0) + 1)}
              onPrev={() => setSelectedItem((selectedItem || 0) - 1 < 0 ? parsedContent.length - 1 : (selectedItem || 0) - 1)}
              showNavigation={parsedContent.length > 1}
            />
          )
        })}
      </Box>
    </Stack>
  )
}

export default MultimodalDisplay
