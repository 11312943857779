import { FileUploaderDropzone } from '@invisible/ui/file-uploader'
import { first } from 'lodash/fp'

import { UploadedFile } from './types'

type ScreenshotUploadProps = {
  updateBRV: (id: undefined | string, value: undefined | string) => Promise<void>
  variable: string | undefined
}

export const ScreenshotUpload = ({ updateBRV, variable }: ScreenshotUploadProps) => {
  const saveUploadedFiles = async (files: UploadedFile[]) => {
    const file = first(files)
    if (file === undefined) {
      return
    }
    updateBRV(variable, file.url)
  }

  return (
    <FileUploaderDropzone
      saveUploadedFiles={saveUploadedFiles}
      directoryName='workDocuments'
      uploadButtonSize='medium'
      showFileCount={false}
      maxUploadAllowed={1}
    />
  )
}
