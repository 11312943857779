import { z } from '@invisible/zod'

const testSchema = z.object({
  input: z.string(),
  output: z.string(),
})

const schema = z.object({
  jsonString: z.string(),
  inputBaseVariableIds: z.string().uuid().array(),
  outputBaseVariableIds: z.string().uuid().array(),
  tests: z.record(testSchema).optional(),
  contexts: z
    .object({
      BASE_RUN_CHILD_CONTEXT: z.boolean().default(false),
    })
    .default({ BASE_RUN_CHILD_CONTEXT: false }),
})

type TSchema = z.infer<typeof schema>
export { schema }
export type { TSchema }
