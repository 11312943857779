import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar'
import { Divider, IconButton, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'

import { getTimeAgoString } from '../../common/helpers'
import { CustomMultiSelect } from '../sub-components/CustomMultiSelect'
import { HeaderProps } from '../types'

export const Header = ({
  showInfoSection,
  setShowInfoSection,
  tokenCount,
  isMinMaxTurnsVariablesSet,
  normalizedConversationData,
  config,
  numOfResponsesPerPrompt,
  visibleResponseIndices,
  setVisibleResponseIndices,
  isUpdated,
  isUpdatingBaseVariables,
  collapseAll,
  setCollapseAll,
}: HeaderProps) => {
  const [lastUpdated, setLastUpdated] = useState(new Date())

  const getMinMaxTurnString = () => {
    const minTurn = isMinMaxTurnsVariablesSet
      ? normalizedConversationData.minTurn
      : config.minMaxTurns?.[0] ?? 1;

    const maxTurn = isMinMaxTurnsVariablesSet
      ? normalizedConversationData.maxTurn
      : config.minMaxTurns?.[1] ?? 1;

    return `${minTurn} to ${maxTurn}`
  }

  useEffect(() => {
    if (isUpdated || !isUpdatingBaseVariables) {
      setLastUpdated(new Date());
    }
  }, [isUpdated, isUpdatingBaseVariables])

  return (<div className='text-paragraphs flex items-center py-3 text-sm font-bold border-solid border-0 border-b-2 border-gray-400 [box-shadow:rgba(96,76,164,0.2)_0px_4px_12px] z-[1]'>
    <IconButton onClick={() => setShowInfoSection(!showInfoSection)} className='text-gray-400'>
      <ViewSidebarIcon />
    </IconButton>
    <Stack direction="row" spacing={1} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
      {(config?.minMaxTurns || isMinMaxTurnsVariablesSet) && (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body2" component="span">
            Turn count:
          </Typography>
          <Typography variant="body2" component="span" fontWeight="medium">
            {getMinMaxTurnString()}
          </Typography>
        </Stack>
      )}
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2" component="span">
          Character count:
        </Typography>
        <Typography variant="body2" component="span" fontWeight="medium">
          {tokenCount}
        </Typography>
      </Stack>
    </Stack>

    <div className='ml-2'>
      <CustomMultiSelect
        label='Visible Responses'
        options={[
          { key: 'Response A', value: 1 },
          { key: 'Response B', value: 2 },
          { key: 'Response C', value: 3 },
          { key: 'Response D', value: 4 },
          { key: 'Response E', value: 5 },
        ].slice(0, numOfResponsesPerPrompt)}
        selectedKeys={visibleResponseIndices}
        onSelect={(selectedKeys) => setVisibleResponseIndices(selectedKeys as number[])}
      />
    </div>
    <IconButton onClick={() => setCollapseAll(!collapseAll)} className='ml-2 text-gray-400'>
      {collapseAll ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
    </IconButton>
    {config.showPreamble ? (
      <div className='ml-2 text-xs'>{normalizedConversationData.preamble}</div>
    ) : null}
    <div className='ml-auto mr-2 text-gray-400'>
      {isUpdated ? `Saved ${getTimeAgoString(lastUpdated)}` : isUpdatingBaseVariables ? 'Saving...' : `Saved ${getTimeAgoString(lastUpdated)}`}
    </div>
  </div>
)}
