import { IStatementOfWorkType, ITermsOfServiceType } from '@invisible/concorde/gql-client'
import React, { createContext, useContext, useReducer } from 'react'

interface SetInitialTosStateAction {
  type: 'setInitialTosState'
  tosToAcknowledge: ITermsOfServiceType | null
  legalName?: string | null
  tosTicketSubmitted: boolean
  isCancelledTos: boolean
  showTosModal: boolean
  showSowModal: boolean
  sowToAcknowledge?: Pick<IStatementOfWorkType, 'id' | 'text'> | null
  sowTicketSubmitted: boolean
  isCancelledSow: boolean
  hasAcknowledgedTosEarlier: boolean
}

interface SetTosTicketSubmittedAction {
  type: 'setTosTicketSubmitted'
  tosTicketSubmitted: boolean
}

interface SetSowTicketSubmittedAction {
  type: 'setSowTicketSubmitted'
  sowTicketSubmitted: boolean
}

interface SetIsCancelledTosAction {
  type: 'setIsCancelledTos'
  isCancelledTos: boolean
}

interface SetIsCancelledSowAction {
  type: 'setIsCancelledSow'
  isCancelledSow: boolean
}

interface SetShowTosModalAction {
  type: 'setShowTosModal'
  showTosModal: boolean
}

interface SetShowSowModalAction {
  type: 'setShowSowModal'
  showSowModal: boolean
}

interface SetSowToAcknowledgeAction {
  type: 'setSowToAcknowledge'
  sowToAcknowledge?: Pick<IStatementOfWorkType, 'id' | 'text'> | null
  showSowModal: boolean
  openWizard?: () => void
  stepRunId?: string | null
}

type TAction =
  | SetInitialTosStateAction
  | SetTosTicketSubmittedAction
  | SetIsCancelledTosAction
  | SetShowTosModalAction
  | SetShowSowModalAction
  | SetSowToAcknowledgeAction
  | SetSowTicketSubmittedAction
  | SetIsCancelledSowAction

type Dispatch = (action: TAction) => void

export interface State {
  tosToAcknowledge: ITermsOfServiceType | null
  legalName?: string | null
  tosTicketSubmitted: boolean
  isCancelledTos: boolean
  showTosModal: boolean
  showSowModal: boolean
  sowToAcknowledge?: Pick<IStatementOfWorkType, 'id' | 'text'> | null
  sowTicketSubmitted: boolean
  isCancelledSow: boolean
  openWizard?: () => void
  hasAcknowledgedTosEarlier: boolean
  stepRunId?: string | null
}

const TosSowStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(
  undefined
)

const initialState = {
  tosToAcknowledge: null,
  legalName: null,
  tosTicketSubmitted: false,
  isCancelledTos: false,
  showTosModal: false,
  showSowModal: false,
  sowToAcknowledge: null,
  sowTicketSubmitted: false,
  isCancelledSow: false,
  hasAcknowledgedTosEarlier: false,
}

export const tosSowStateReducer = (state: State, action: TAction) => {
  switch (action.type) {
    case 'setInitialTosState': {
      return {
        ...state,
        tosToAcknowledge: action.tosToAcknowledge,
        legalName: action.legalName,
        tosTicketSubmitted: action.tosTicketSubmitted,
        isCancelledTos: action.isCancelledTos,
        showTosModal: action.showTosModal,
        showSowModal: action.showSowModal,
        sowToAcknowledge: action.sowToAcknowledge,
        sowTicketSubmitted: action.sowTicketSubmitted,
        isCancelledSow: action.isCancelledSow,
        hasAcknowledgedTosEarlier: action.hasAcknowledgedTosEarlier,
      }
    }

    case 'setTosTicketSubmitted': {
      return {
        ...state,
        tosTicketSubmitted: action.tosTicketSubmitted,
      }
    }

    case 'setSowTicketSubmitted': {
      return {
        ...state,
        sowTicketSubmitted: action.sowTicketSubmitted,
      }
    }

    case 'setIsCancelledSow': {
      return {
        ...state,
        isCancelledSow: action.isCancelledSow,
      }
    }

    case 'setIsCancelledTos': {
      return {
        ...state,
        isCancelledTos: action.isCancelledTos,
      }
    }

    case 'setShowTosModal': {
      return {
        ...state,
        showTosModal: action.showTosModal,
      }
    }

    case 'setShowSowModal': {
      return {
        ...state,
        showSowModal: action.showSowModal,
      }
    }
    case 'setSowToAcknowledge': {
      return {
        ...state,
        sowToAcknowledge: action.sowToAcknowledge,
        showSowModal: action.showSowModal,
        openWizard: action.openWizard,
        stepRunId: action.stepRunId,
      }
    }
    default: {
      return state
    }
  }
}

export const TosSowStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(tosSowStateReducer, initialState)
  const value = { state, dispatch }
  return <TosSowStateContext.Provider value={value}>{children}</TosSowStateContext.Provider>
}

export const useTosSowState = () => {
  const context = useContext(TosSowStateContext)
  if (context === undefined) {
    throw new Error('useTosSowState must be used within a TosSowStateProvider')
  }
  return context
}
