import { UuidSchema, z } from '@invisible/zod'

const schema = z.object({
  pdfUrlBaseRunVariableId: UuidSchema.optional(),
  pdfUrlString: z.string().optional(),
  codeBaseRunVariableId: UuidSchema.optional(),
})

type TSchema = z.infer<typeof schema>

export { schema }
export type { TSchema }
