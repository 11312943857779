import { getErrorMessage } from '@invisible/concorde/gql-client'
import { sendErrorToSentry } from '@invisible/errors'
import { Button } from '@invisible/ui/button'
import axios from 'axios'
import React from 'react'
import { useToasts } from 'react-toast-notifications'

import { NEXT_PUBLIC_CONCORDE_URL } from '../../../config/env'
import { TBaseRunQueryData } from '../hooks/useGetBaseRuns'

type TBaseRun = TBaseRunQueryData['items'][number]
type TStepRun = TBaseRun['stepRuns'][number]

type IProps = {
  name: string
  stepRun: TStepRun
}

const ValidateButtonWAC = ({ name, stepRun }: IProps) => {
  const { addToast } = useToasts()
  const [isLoading, setIsLoading] = React.useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    try {
      await axios.post(
        `${NEXT_PUBLIC_CONCORDE_URL}/api/wizard/validate/`,
        {
          step_run_id: stepRun.id as string,
        },
        {
          withCredentials: true,
        }
      )
    } catch (error) {
      sendErrorToSentry(error)
      const message = getErrorMessage(error)
      addToast(`Something went wrong: ${message}`, {
        appearance: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='mt-2 flex justify-end'>
      <Button variant='primary' size='md' onClick={handleClick} disabled={isLoading}>
        {name}
      </Button>
    </div>
  )
}

export { ValidateButtonWAC }
