import { fromGlobalId, useUserActiveWorkingProcessIDsQuery } from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useEffect } from 'react'

export function useActiveProcessIds() {
  const query = useUserActiveWorkingProcessIDsQuery()
  const { isSuccess, data, isError, error } = query

  useEffect(() => {
    if (isSuccess) {
      logger.info('User active working process IDs fetched', {
        userId: fromGlobalId(data.me.id),
        activeProcessIDs: data.me.activeWorkingProcessIds?.map((id) => fromGlobalId(id)),
      })
    }
  }, [data, isSuccess])

  useEffect(() => {
    if (isError) {
      logger.error('Failed to fetch user active working process IDs', { error })
    }
  }, [error, isError])

  return query
}
