/* eslint-disable @typescript-eslint/ban-types */
import 'react-date-range/dist/styles.css'
import '../../../libs/ui/src/lib/styles/date-picker.css'
import '../../../global.css'

import { datadogRum } from '@datadog/browser-rum'
import { NEXTAUTH_URL } from '@invisible/authentication/internal'
import { withAbilityContext } from '@invisible/authorization/client'
import { GraphqlQueryProvider } from '@invisible/common/providers'
import { createApp } from '@invisible/common/providers'
import { LoadingModal } from '@invisible/ui/loading-modal'
import { LicenseInfo } from '@mui/x-license'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import type { Session } from 'next-auth'
import { signIn, useSession } from 'next-auth/react'
import { FC, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { Layout } from '../common/Layout'

LicenseInfo.setLicenseKey(
  '124b228422f7188d38cd9498c788f9fbTz0xMDcwNzAsRT0xNzQ4NTYzMTk5MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y'
)

const withSessionContext =
  <T,>(Component: FC<T>): FC<T> =>
  (props) => {
    const router = useRouter()
    const queryClient = new QueryClient()
    const { status } = useSession({
      required: true,
      onUnauthenticated() {
        signIn('auth0', { callbackUrl: `${NEXTAUTH_URL}${router.asPath}` }, { prompt: 'login' })
      },
    })
    const Page =
      status === 'loading' ? (
        <LoadingModal />
      ) : (
        <QueryClientProvider client={queryClient}>
          <Component {...props} />{' '}
        </QueryClientProvider>
      )
    return Page
  }

const LayoutWithGraphqlQueryProvider: FC = ({ children }) => (
  <GraphqlQueryProvider>
    <Layout>{children}</Layout>
  </GraphqlQueryProvider>
)

const AppBody = createApp({
  Layout: withSessionContext(withAbilityContext(LayoutWithGraphqlQueryProvider)),
  pageTitle: 'Invisible - Admin',
})

const Page = (props: AppProps<{ session?: Session }>) => {
  const router = useRouter()

  useEffect(() => {
    datadogRum.startView({ name: router.asPath })
  })

  return <AppBody {...props} />
}

export default Page
