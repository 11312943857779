import { Button } from '@invisible/ui/button'
import Box from '@mui/material/Box'
import dynamic from 'next/dynamic'
import { useCallback, useState } from 'react'
import { useGate } from 'statsig-react'

import { useBaseRunVariablesWizardUpdate } from '../../hooks/useBaseRunVariablesWizardUpdate'
import { ForkedCodeWAC } from './ForkedCodeWAC'
import { IComboMonacoPDFWACProps, Move, ScrollDirection } from './types'

const PDFWACNoSSR = dynamic(
  () =>
    import('./PDFWAC').catch((error) => {
      console.error(error)
      throw error
    }),
  {
    ssr: false,
  }
)

const ComboMonacoPDFWAC = (props: IComboMonacoPDFWACProps) => {
  const { readOnly, baseRun, stepRun, comboMonacoPDF: config } = props
  const { mutateAsync: updateBaseRunVariables } = useBaseRunVariablesWizardUpdate()
  const [move, setMove] = useState<undefined | Move>(undefined)
  const [scrollEnabled, setScrollEnabled] = useState(true)

  const { value: enablePDFWAC } = useGate('enable-pdfwac')

  const handleVisiblePdfText = useCallback((visibleText: string, direction: ScrollDirection) => {
    setMove({ text: visibleText, source: 'pdf', direction })
  }, [])

  const values = Object.fromEntries(
    baseRun.baseRunVariables.map((variable) => [variable.baseVariable.id, variable.value])
  )
  const codeBRVId = config?.codeBaseRunVariableId ?? ''
  const code = values[codeBRVId] as string | undefined
  const pdfUrlBRV = values[config?.pdfUrlBaseRunVariableId ?? ''] as string | undefined
  const pdfUrlString = ('https://' + values[config?.pdfUrlString ?? '']) as string | undefined
  const pdf = pdfUrlBRV ?? pdfUrlString

  const updateBRV = async (id: string | undefined, value: string | undefined) => {
    // TODO: we should probbably throw some sort of error on undefined values
    if (id && typeof value === 'string') {
      await updateBaseRunVariables({
        stepRunId: stepRun.id,
        data: [{ baseRunId: stepRun.baseRunId, baseVariableId: id, value }],
      })
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box sx={{ height: 32 }}>
        <Button onClick={() => setScrollEnabled((prev) => !prev)}>
          {scrollEnabled ? 'Disable' : 'Enable'} Sync Scrolling
        </Button>
      </Box>
      <Box
        sx={{
          flex: 1,
          height: '100%',
          minHeight: 0,
          display: 'flex',
          borderRadius: 2,
          border: '2px solid #C3DAFE',
          '& > *:first-of-type': {
            flexShrink: 0,
            flexGrow: 0,
            flexBasis: 'auto',
          },
          '& > *:last-child': {
            flex: 1,
            minWidth: 0,
          },
        }}>
        {enablePDFWAC ? (
          <PDFWACNoSSR
            pdf={pdf}
            onVisibleTextChange={handleVisiblePdfText}
            currentMove={scrollEnabled ? move : undefined}
          />
        ) : (
          <p>PDFWAC is disabled - enable in statsig gate</p>
        )}
        <ForkedCodeWAC
          readOnly={readOnly}
          code={code || ''}
          onChangeMethod={(inputText) => updateBRV(codeBRVId, inputText)}
          move={scrollEnabled ? move : undefined}
          setMove={setMove}
        />
      </Box>
    </Box>
  )
}

export { ComboMonacoPDFWAC }
