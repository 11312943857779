import { SnackbarContext } from '@invisible/common/providers'
import { fromGlobalId, toGlobalId } from '@invisible/concorde/gql-client'
import {
  getErrorMessage,
  useBaseRunVariablesWizardUpdateMutation,
} from '@invisible/concorde/gql-client'
import { logger } from '@invisible/logger/client'
import { useContext } from 'react'
import { JsonValue } from 'type-fest'

interface OnMutateVariable {
  value: JsonValue
  baseRunId?: string
  baseVariableId?: string
  baseRunVariableId?: string
}

export interface UpdateItem extends OnMutateVariable {
  baseRunId: string
  baseVariableId: string
}

interface MutationInput {
  stepRunId: string
  data: UpdateItem[]
}

type OnMutateHandler = (variables: OnMutateVariable[]) => Promise<void>

const useBaseRunVariablesWizardUpdate = ({
  onSuccess,
  onError: handleError,
  onMutate: handleOnMutate,
  onSettled,
}: {
  onError?: (error: Error) => void
  onSuccess?: (data: any) => void
  onSettled?: () => void
  onMutate?: OnMutateHandler
} = {}) => {
  const { showSnackbar } = useContext(SnackbarContext)

  const { mutateAsync: updateBaseRunVariablesWizard, isLoading } =
    useBaseRunVariablesWizardUpdateMutation({
      onError: (error, variables) => {
        const _error = new Error(getErrorMessage(error))

        const { stepRunId } = variables.input

        logger.error(
          'Mutating from useBaseRunVariablesWizardUpdate resulted in an error',
          { stepRunId: fromGlobalId(stepRunId) },
          _error
        )
        if (handleError) {
          handleError(_error)
          return
        }
        showSnackbar({
          message: _error.message,
          variant: 'error',
        })
      },
      onSuccess,
      onSettled,
      onMutate: handleOnMutate
        ? async (variables) => {
            const { input } = variables
            const transformedVariables = input.updateData.map((item) => ({
              value: item.value ?? null,
              baseRunId: fromGlobalId(item.baseRunId),
              baseVariableId: fromGlobalId(item.baseVariableId),
            }))
            await handleOnMutate(transformedVariables)
          }
        : undefined,
    })

  const mutateAsync = ({ stepRunId, data }: MutationInput) => {
    logger.info('Mutating from useBaseRunVariablesWizardUpdate', {
      stepRunId,
      data: data.map((item) => ({
        baseRunId: item.baseRunId,
        baseVariableId: item.baseVariableId,
      })),
    })
    return updateBaseRunVariablesWizard({
      input: {
        stepRunId: toGlobalId('StepRunType', stepRunId),
        updateData: data.map((item) => ({
          value: item.value ?? null,
          baseRunId: toGlobalId('BaseRunType', item.baseRunId),
          baseVariableId: toGlobalId('BaseVariableType', item.baseVariableId),
        })),
      },
    })
  }

  return {
    mutateAsync,
    isLoading,
  }
}

export { useBaseRunVariablesWizardUpdate }
