import { getUUIDFromNamespace } from '@invisible/common/helpers'
import { IStepRunEventTypeEnum } from '@invisible/concorde/gql-client'
import { useQuery } from '@invisible/trpc/client'
import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Avatar from '@mui/material/Avatar'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Prisma } from '@prisma/client'
import { groupBy } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'

import { useStepRunEventLogger } from '../../../hooks/useStepRunEventLogger'
import TextRender from '../../common/TextRender'
import { TTextDirection } from '../../common/types'
import { useRLHFContext } from '../context'
import { TTurnProps } from '../types'
import AcceptedResponse from './AcceptedResponse'
import MultimodalDisplay from './MultimodalDisplay'
import { PromptEdit } from './PromptEdit'
import ResponseSelection from './ResponseSelection'

const Turn = ({ collapsed, prompt, loggedInUser, defaultRenderMode }: TTurnProps) => {
  const [localCollapsed, setLocalCollapsed] = useState(collapsed ?? (!!prompt.acceptedResponse || false))
  const [promptEditToggle, setPromptEditToggle] = useState(false)
  const [isEditingResponse, setIsEditingResponse] = useState(false)
  const { maybeLogStepRunEvent } = useStepRunEventLogger()
  const {
    config,
    stepRunId,
    firstManualStepRunCreatedAt,
    numOfPrompts,
    visibleResponseIndices,
    resubmitPrompt,
    deletePrompt,
    refetchPromptResponses,
    isReadOnly,
    loaders: {
      isCreatingManyBaseRuns,
      isCreatingBaseRun,
      isDeletingBaseRuns,
      isUpdatingBaseVariables,
    } = {},
  } = useRLHFContext()

  const { data: activePromptResponses, isLoading: isFetchingResponses } = useQuery(
    [
      'baseRun.findChildBaseRuns',
      {
        baseId: config?.responsesBaseId as string,
        parentBaseRunId: prompt.id as string,
      },
    ],
    {
      enabled: isEditingResponse || !prompt.acceptedResponse,
    }
  )

  const normalizedActivePromptResponses = useMemo(() => {
    const sortedResponses = (activePromptResponses ?? [])
      .map((response) => ({
        id: response.id,
        text: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseTextBaseVariableId
        )?.value as string,
        originalText: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseOriginalTextBaseVariableId
        )?.value as string,
        content: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseContentBaseVariableId
        )?.value as Prisma.JsonArray,
        extraMetadata: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseExtraMetadataBaseVariableId
        )?.value as Prisma.JsonObject,
        index: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseIndexBaseVariableId
        )?.value as number,
        model: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseModelBaseVariableId
        )?.value as string,
        rank: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseRankBaseVariableId
        )?.value as number,
        rating: response.baseRunVariables.find(
          (variable) => variable.baseVariable.id === config?.responseRatingBaseVariableId
        )?.value as number,
        baseRunVariables: response.baseRunVariables,
        selected: false,
      }))
      .filter((response) => response.originalText)
      .sort((a, b) => a.index - b.index)
    const groupedResponses = groupBy('model', sortedResponses)
    return { flattened: sortedResponses, grouped: groupedResponses }
  }, [activePromptResponses, config])

  const highestRankedResponseIds = useMemo(() => {
    const selectedResponse = normalizedActivePromptResponses.flattened.reduce(
      (acc, cur) => {
        if (!cur.rank || !cur.rating) return acc

        if (cur.rank < (acc.rank > 0 ? acc.rank : Infinity)) {
          return cur
        }
        if (cur.rank === acc.rank) {
          return cur.rating > acc.rating ? cur : acc
        }
        return acc
      },
      { rank: 0, rating: 0, id: '' }
    )

    const sameRankRatingResponses = normalizedActivePromptResponses.flattened.filter(
      (response) =>
        response.id &&
        selectedResponse?.rating === response.rating &&
        selectedResponse?.rank === response.rank &&
        selectedResponse?.id !== response.id &&
        response.rating !== null &&
        response.rank !== null
    )

    return [
      ...(selectedResponse?.id ? [selectedResponse?.id] : []),
      ...sameRankRatingResponses.map((response) => response.id),
    ]
  }, [normalizedActivePromptResponses])

  useEffect(() => {
    if (collapsed !== undefined) {
      setLocalCollapsed(collapsed)
    }
  }, [collapsed])

  const promptIndex = prompt.index + 1
  const isLastTurn = promptIndex === numOfPrompts

  const handleEditPrompt = () => {
    maybeLogStepRunEvent({
      name: 'editing_prompt',
      stepRunId: stepRunId as string,
      spanId: getUUIDFromNamespace([stepRunId as string, String(promptIndex)]),
      spanType: 'TURN',
      spanLocation: promptIndex,
      type: IStepRunEventTypeEnum.Span,
      timestamp: new Date(),
    })
    setPromptEditToggle(!promptEditToggle)
  }

  const handleEditResponse = () => {
    maybeLogStepRunEvent({
      name: 'editing_response',
      stepRunId: stepRunId as string,
      spanId: getUUIDFromNamespace([stepRunId as string, String(promptIndex)]),
      spanType: 'TURN',
      spanLocation: promptIndex,
      type: IStepRunEventTypeEnum.Span,
      timestamp: new Date(),
    })
    setIsEditingResponse(true)
  }

  const textDirection = config.promptResponseTextDirection ?? 'auto'

  return (
    <div className='mb-4 w-full'>
      <div
        className='mb-4 flex cursor-pointer items-center'
        onClick={() => {
          setLocalCollapsed(!localCollapsed)
          if (prompt.acceptedResponse) {
            setIsEditingResponse(false)
          }
        }}>
        <span className='mr-2 text-gray-400 flex items-center'>
          {localCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
        <Typography variant="body2" fontWeight="bold">
          Turn {promptIndex}
        </Typography>
      </div>

      {localCollapsed ? null : (
        <div className='ml-8'>
          <div className='mb-2'>
            {promptEditToggle ? (
              <PromptEdit
                baseRunVariables={prompt.baseRunVariables ?? []}
                disableMetadataEdit={!config.allowPromptMetadataEdit && !isLastTurn}
                disablePromptEdit={
                  isReadOnly ||
                  !config.allowPromptEditing ||
                  (config.allowPromptEditing && config.allowEditingPreviousTurns ? false : !isLastTurn)
                }
                prompt={prompt}
                textDirection={textDirection as TTextDirection}
                handleClose={() => setPromptEditToggle(false)}
              />
            ) : (
              <div className='relative flex w-full flex-row items-start gap-2'>
                <Avatar
                  sx={{
                    height: 24,
                    width: 24,
                    marginRight: 1,
                  }}
                  src={loggedInUser?.image || ''}
                />
                <div className={`flex-1 rounded bg-[#E0E7FF] py-1 px-2 text-sm`}>
                  <TextRender
                    text={prompt.text}
                    renderMode={defaultRenderMode ?? 'markdown'}
                    markdownProps={{
                      components: {
                        p: ({ children }) => <p className='whitespace-pre-wrap'>{children}</p>
                      },
                      className: 'overflow-auto',
                      dir: textDirection as TTextDirection
                    }}
                    plaintextProps={{
                      className: 'my-3 overflow-auto',
                    }}
                    latexProps={{
                      containerClassName: 'my-3 overflow-auto',
                      className: 'whitespace-pre-wrap',
                      textDirection: textDirection as TTextDirection,
                    }}
                  />
                  <MultimodalDisplay content={prompt.attachedFiles} />
                </div>
                <div className='ml-2 mt-2 flex-none'>
                  <IconButton
                    title='Edit Prompt'
                    size='small'
                    onClick={handleEditPrompt}
                    className='text-gray-400 flex items-center'>
                    <EditIcon />
                  </IconButton>

                  {config.allowPromptDeletion && !isFetchingResponses && (
                    <IconButton
                      title='Delete'
                      disabled={isDeletingBaseRuns || isReadOnly}
                      onClick={() => deletePrompt(prompt)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                  {
                    /*
                      The ability to Refetch Responses will only be available to the most recent Prompt at a maximum.
                      We can NEVER refetch Responses for older turns, as ensured by the `isLastTurn` condition below.

                      If allowPromptRefetch is True && allowPromptRefetchOnImportedTurns is False,
                      the most recent Prompt will need to be non-imported for Refetch to work (prompt.createdAt > firstManualStepRunCreatedAt).

                      If allowPromptRefetch is True && allowPromptRefetchOnImportedTurns is True,
                      the most recent Prompt will have Refetch work even if it is imported.

                      If allowPromptRefetch is False, allowPromptRefetchOnImportedTurns will always be False.
                      In this case, Refetch option will not be available even to the most recent Turn.
                    */
                    !config.allowPromptRefetch ? null : (
                      <>
                        {!isFetchingResponses &&
                          isLastTurn &&
                          (prompt.createdAt > firstManualStepRunCreatedAt ||
                            config.allowPromptRefetchOnImportedTurns) && (
                            <IconButton
                              title='Refetch Response'
                              disabled={
                                isDeletingBaseRuns ||
                                isCreatingManyBaseRuns ||
                                isUpdatingBaseVariables ||
                                isReadOnly
                              }
                              onClick={() => refetchPromptResponses({
                                ...prompt,
                                attachedFiles: prompt.attachedFiles ?? []
                              })}>
                              <CachedIcon />
                            </IconButton>
                          )}

                        {!prompt.acceptedResponse &&
                          !isFetchingResponses &&
                          !isLastTurn &&
                          !(normalizedActivePromptResponses.flattened.length > 0) && (
                            <IconButton
                              title='Resubmit prompt'
                              className='hidden'
                              disabled={
                                isFetchingResponses ||
                                isCreatingManyBaseRuns ||
                                isCreatingBaseRun ||
                                isReadOnly
                              }
                              onClick={() => resubmitPrompt({
                                ...prompt,
                                attachedFiles: prompt.attachedFiles ?? []
                              })}>
                              <CachedIcon />
                            </IconButton>
                          )}
                      </>
                    )
                  }
                </div>
              </div>
            )}
          </div>

          {/* Prompt Responses */}
          {isEditingResponse || !prompt.acceptedResponse ? (
            <>
              {prompt.acceptedResponse && (
                <div className='flex justify-end'>
                  <IconButton
                    className=' cursor-pointer text-gray-400'
                    onClick={() => setIsEditingResponse(false)}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
              <div className='relative flex w-full gap-2 overflow-auto pt-1' key={prompt.id}>
                {(normalizedActivePromptResponses.flattened ?? []).map((response, index) =>
                  (visibleResponseIndices ?? []).includes(index + 1) ? (
                    <ResponseSelection
                      defaultRenderMode={defaultRenderMode}
                      response={response}
                      index={index}
                      prompt={prompt}
                      normalizedActivePromptResponses={normalizedActivePromptResponses}
                      highestRankedResponseIds={highestRankedResponseIds}
                      setIsEditingResponse={setIsEditingResponse}
                      stepRunId={stepRunId as string}
                      config={config}
                      isLastTurn={isLastTurn}
                      key={response.id}
                    />
                  ) : null
                )}

                {(normalizedActivePromptResponses.flattened ?? []).filter((_, index) =>
                  (visibleResponseIndices ?? []).includes(index + 1)
                ).length === 0 &&
                  !(
                    isFetchingResponses ||
                    isCreatingManyBaseRuns ||
                    isUpdatingBaseVariables ||
                    isCreatingBaseRun
                  ) && (
                    <div className='text-gray-400'>
                      No responses to show - check Visible Responses or reload responses
                    </div>
                  )}
                {(normalizedActivePromptResponses.flattened ?? []).filter((_, index) =>
                  (visibleResponseIndices ?? []).includes(index + 1)
                ).length === 0 &&
                  isFetchingResponses && (
                    <div className='flex w-full items-center justify-center'>
                      <CircularProgress />
                    </div>
                  )}
              </div>
            </>
          ) : (
            <AcceptedResponse
              prompt={prompt}
              textDirection={textDirection as TTextDirection}
              firstManualStepRunCreatedAt={firstManualStepRunCreatedAt}
              config={config}
              onEditResponse={handleEditResponse}
              defaultRenderMode={defaultRenderMode}
            />
          )}
        </div>
      )}
    </div>
  )
}

export { Turn }
