import { Fzf } from 'fzf'
import type * as MonacoEditor from 'monaco-editor/esm/vs/editor/editor.api'

import type { ScrollChangeData, ScrollDirection, SpanChunkMap } from './types'

interface BestMatch {
  element: HTMLElement
  distance: number
}

// Create a map of a concatenated strings of spans to the first span in that chunk.

export function createSpanChunks(spans: HTMLElement[], chunkSize: number): SpanChunkMap {
  const chunks: SpanChunkMap = {}

  console.log('Received spans:', spans.length, 'chunkSize:', chunkSize)

  if (!spans.length || chunkSize <= 0) {
    console.log('No spans or invalid chunk size')
    return chunks
  }

  for (let i = 0; i < spans.length; i += chunkSize) {
    const chunk = spans.slice(i, i + chunkSize)
    const concatenatedText = chunk.map((span) => span.textContent?.trim() ?? '').join(' ')

    if (concatenatedText) {
      // Store all elements in the chunk instead of just the first one
      chunks[concatenatedText] = chunk
    }
  }

  console.log('Span chunks:', chunks)
  return chunks
}

export function getCandidateSpans(
  pdfContainer: HTMLElement,
  referenceElement: HTMLElement | null,
  direction: 'up' | 'down'
): HTMLElement[] {
  const spans = pdfContainer.querySelectorAll<HTMLElement>('.react-pdf__Page__textContent span')
  const allSpans = Array.from(spans)

  if (!referenceElement) return allSpans

  const refIndex = allSpans.indexOf(referenceElement)
  if (refIndex === -1) {
    return allSpans
  }

  return direction === 'down' ? allSpans.slice(refIndex + 1) : allSpans.slice(0, refIndex)
}

export function normalizeText(input: string): string {
  return input.toLowerCase().replace(/\s+/g, ' ').trim()
}

export function findBestMatchingChunk(
  targetText: string,
  spanChunks: SpanChunkMap,
  direction: 'up' | 'down',
  pdfContainer: HTMLElement,
  lastIntersectingElement: HTMLElement | null
): HTMLElement | null {
  if (!targetText) return null
  if (targetText.length < 10) return null

  const normalizedTarget = normalizeText(targetText)
  const candidateSpans = getCandidateSpans(pdfContainer, lastIntersectingElement, direction)

  // only use chunks whose elements are in candidateSpans so we don't scroll in the
  // wrong direction
  const filteredChunks: SpanChunkMap = {}
  Object.entries(spanChunks).forEach(([text, elements]) => {
    if (elements.some((element) => candidateSpans.includes(element))) {
      filteredChunks[text] = elements
    }
  })

  const fzfList = Object.entries(filteredChunks).map(([text, elements]) => ({
    text,
    elements,
  }))

  const fzf = new Fzf(fzfList, {
    selector: (item) => item.text,
    casing: 'case-insensitive',
    normalize: true,
    fuzzy: 'v2',
    limit: Infinity,
    sort: true,
    tiebreakers: [(a, b) => a.item.text.length - b.item.text.length],
  })

  const entries = fzf.find(normalizedTarget)
  const bestMatch = entries[0]

  if (!bestMatch) return null

  const elements = bestMatch.item.elements
  const middleIndex = Math.floor(elements.length / 2)
  const bestElement = elements[middleIndex]

  console.log(`FZF chunk match result:
    Target Text: "${normalizedTarget}"
    Best Match Text: "${bestMatch.item.text}"
    Chunk Score: ${bestMatch.score}
    Direction: ${direction}`)

  return bestElement
}

export function getScrollDirection(change: ScrollChangeData): ScrollDirection {
  const deltaX = change.scrollLeft - change._oldScrollLeft
  const deltaY = change.scrollTop - change._oldScrollTop

  if (change.scrollTopChanged) {
    return deltaY > 0 ? 'down' : 'up'
  }
  if (change.scrollLeftChanged) {
    return deltaX > 0 ? 'right' : 'left'
  }

  return 'none'
}

function getElementPageTop(element: HTMLElement) {
  const rect = element.getBoundingClientRect()
  return rect.top + window.scrollY
}

export function aIsAboveB(a: HTMLElement, b: HTMLElement): boolean {
  const topA = getElementPageTop(a)
  const topB = getElementPageTop(b)
  return topA > topB
}
